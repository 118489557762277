import axios from 'axios';
import { API_URL } from './api-url.config';
// ;

const title = window.platform_info.title;
export const API = axios.create({
  baseURL: API_URL
});

export const anonimousUser = async () => {
  const platform = window.platform_info.api_key;

  const data = {
    platform: platform
  };

  try {
    const response = await axios.post(`${API_URL}auth/token/temp`, JSON.stringify(data), {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    });

    const { token, refreshToken } = response.data;

    localStorage.setItem(`access_token_temp_${title}`, JSON.stringify(token));
    localStorage.setItem(`refresh_token_temp_${title}`, JSON.stringify(refreshToken));
    localStorage.setItem(`time_temp_${title}`, Date.now() + 172800000);

    return token;
  } catch (error) {
    console.error('Error fetching temporary token:', error);
  }
};

// Add a request interceptor

API.interceptors.request.use(async function (config) {

  let token = JSON.parse(localStorage.getItem(`access_token_r_${title}`));
  let tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
  let beforeTimeTemp = JSON.parse(localStorage.getItem(`time_temp_${title}`));

  const currentTime = Date.now();

  if ((!tempToken && !token)) {
    const resolvedToken = await anonimousUser();
    config.headers.Authorization = `Bearer ${resolvedToken}`;
    console.log('anonimous user token', resolvedToken);
    return config;
  } else {
    console.log("Authorization Token before request", token);
    config.headers.Authorization = token ? `Bearer ${token}` : `Bearer ${tempToken}`;
    return config;
  }
});

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response: { status = '' } = {} } = error;
    const originalReq = config;

    const token = JSON.parse(localStorage.getItem(`access_token_r_${title}`));
    const tempToken = JSON.parse(localStorage.getItem(`access_token_temp_${title}`));
    const refreshToken = JSON.parse(localStorage.getItem(`refresh_token_r_${title}`));

    console.log('status', status);
    console.log('originalReq', originalReq);

    // Initialize retry counter if it doesn't exist
    originalReq._retryCount = originalReq._retryCount || 0;

    if ((status === 401 || status === 403) && originalReq._retryCount < 3) {
      originalReq._retryCount += 1;

      if (tempToken) {
        try {
          const resolvedToken = await anonimousUser();
          originalReq.headers['Authorization'] = `Bearer ${resolvedToken}`;
          console.log('token after getting anonimous/temp token', resolvedToken);
          return API(originalReq);
        } catch (err) {
          console.log('Error fetching anonimous token');
          return Promise.reject(err);
        }
      } else if (token) {
        try {
          console.log('token before calling refresh api', token);
          const response = await fetch(`${API_URL}auth/token/refresh`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ token: refreshToken })
          });

          const resData = await response.json();
          localStorage.setItem(`access_token_r_${title}`, JSON.stringify(resData.token));
          localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(resData.refreshToken));

          originalReq.headers['Authorization'] = `Bearer ${resData.token}`;
          console.log('token after getting refresh token', resData.token);
          return API(originalReq);
        } catch (err) {
          console.log("Error in fetching refresh token from api");
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(error);
  }
);

