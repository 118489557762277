import React, { lazy, Suspense, useEffect, useState, useMemo, useContext } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'components/layout';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/InlineLoader';
import ScrollToTop from 'components/scroll-to-top';
import { toast } from 'react-toastify';
import Progress from 'react-progress';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-multi-carousel/lib/styles.css';
import { Helmet } from 'react-helmet';
import { GlobalStyles } from 'assets/styled/global.styled';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'assets/styled/Theme';
import useGaTracker from 'useGatracker';
import CookieConsent from 'react-cookie-consent';
import jwt from 'jwt-decode';
import { setUserSub } from 'redux/userSubInfo/userSub.slice';
import firebase from 'firebase';
import useAnanymousCheck from 'useAnanymousCheck';
import SubscribeRedirect from 'components/settings/SubscribeRedirect';
import { setVastTag } from 'redux/video/video.slice';
import { callbackTypeOne } from 'utils/gp-callbacks';
import VideoDetailSlug from 'pages/VideoDetailSlug';
import SeriesDetailSlug from 'pages/SeriesDetailSlug';
import MetaPixel from 'utils/metaPixel';
import { lazyWithRetry } from 'utils/module-loader';

// Dynamic Imports Goes Here.
// const SearchPage = lazy(() => import('pages/SearchPage'));
// const FavouriteSeeAll = lazy(() => import('pages/FavouriteSeeAll'));
// const ScrollToTopButton = lazy(() => import('components/ScrollToTopButton'));
// const PremiumModal2 = lazy(() => import('components/settings/PremiumModal2'));
// const Package1 = lazy(() => import('components/settings/Package1'));
// const ChannelsSeemore = lazy(() => import('pages/ChannelsSeemore'));
// const AllPopularArtist = lazy(() => import('pages/AllPopularArtist'));
// const Settings = lazy(() => import('pages/Settings'));
// const ArtistPageRebuilt = lazy(() => import('pages/ArtistPageRebuilt'));
// const CouponAuth = lazy(() => import('components/CouponAuth'));
// const Modals = lazy(() => import('components/modals'));
// const Login = lazy(() => import('pages/Login'));
// const PaymentConfirmation = lazy(() => import('pages/PaymentConfirmation'));
// const CallbackPaymentPage = lazy(() => import('pages/CallbackPaymentPage'));
// const Home = lazy(() => import('pages/Home'));
// const Audios = lazy(() => import('pages/Audios'));
// const Videos = lazy(() => import('pages/Videos'));
// const AudioDetail = lazy(() => import('pages/AudioDetail'));
// const AudioDetailSlug = lazy(() => import('pages/AudioDetailSlug'));
// const AlbumDetail = lazy(() => import('pages/AlbumDetail'));
// const AlbumDetailSlug = lazy(() => import('pages/AlbumDetailSlug'));
// const VideoDetail = lazy(() => import('pages/VideoDetail'));
// const ChannelPlay = lazy(() => import('pages/ChannelPlay'));
// const SeriesDetail = lazy(() => import('pages/SeriesDetail'));
// const CatchupDetail = lazy(() => import('pages/CatchupDetail'))
// const CategoryPage = lazy(() => import('pages/CategoryPage'));
// const CategoryPageSlug = lazy(() => import('pages/CategoryPageSlug'));
// const RecommendationPage = lazy(() => import('pages/RecommendationPage'));
// const Favourite = lazy(() => import('pages/Favourite'));
// const NotFound = lazy(() => import('pages/NotFound'));
// const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
// const TermsOfUse = lazy(() => import('pages/TermsOfUse'));
// const AboutCompany = lazy(() => import('pages/AboutCompany'));
// const PlaystorePopup = lazy(() => import('components/PlaystorePopup'));
// const ContactUs = lazy(() => import('pages/ContactUs'));
// const Pricing = lazy(() => import('pages/Pricing'));
// const Original = lazy(() => import('pages/Originals'));
// 37 routes

// const UserSettings = React.lazyWithRetry(() => import(/* webpackChunkName: "userSettings" */ './settings')));
const SearchPage = lazyWithRetry(() => import('pages/SearchPage'));
const FavouriteSeeAll = lazyWithRetry(() => import('pages/FavouriteSeeAll'));
const ScrollToTopButton = lazyWithRetry(() => import('components/ScrollToTopButton'));
const PremiumModal2 = lazyWithRetry(() => import('components/settings/PremiumModal2'));
const Package1 = lazyWithRetry(() => import('components/settings/Package1'));
const ChannelsSeemore = lazyWithRetry(() => import('pages/ChannelsSeemore'));
const AllPopularArtist = lazyWithRetry(() => import('pages/AllPopularArtist'));
const Settings = lazyWithRetry(() => import('pages/Settings'));
const ArtistPageRebuilt = lazyWithRetry(() => import('pages/ArtistPageRebuilt'));
const CouponAuth = lazyWithRetry(() => import('components/CouponAuth'));
const Modals = lazyWithRetry(() => import('components/modals'));
const Login = lazyWithRetry(() => import('pages/Login'));
const PaymentConfirmation = lazyWithRetry(() => import('pages/PaymentConfirmation'));
const CallbackPaymentPage = lazyWithRetry(() => import('pages/CallbackPaymentPage'));
const Home = lazyWithRetry(() => import('pages/Home'));
const Audios = lazyWithRetry(() => import('pages/Audios'));
const Videos = lazyWithRetry(() => import('pages/Videos'));
const Movie = lazyWithRetry(() => import('pages/Movie'));
const AudioDetail = lazyWithRetry(() => import('pages/AudioDetail'));
const AudioDetailSlug = lazyWithRetry(() => import('pages/AudioDetailSlug'));
const AlbumDetail = lazyWithRetry(() => import('pages/AlbumDetail'));
const AlbumDetailSlug = lazyWithRetry(() => import('pages/AlbumDetailSlug'));
const VideoDetail = lazyWithRetry(() => import('pages/VideoDetail'));
const ChannelPlay = lazyWithRetry(() => import('pages/ChannelPlay'));
const SeriesDetail = lazyWithRetry(() => import('pages/SeriesDetail'));
const CatchupDetail = lazyWithRetry(() => import('pages/CatchupDetail'));
const CategoryPage = lazyWithRetry(() => import('pages/CategoryPage'));
const CategoryPageSlug = lazyWithRetry(() => import('pages/CategoryPageSlug'));
const RecommendationPage = lazyWithRetry(() => import('pages/RecommendationPage'));
const Favourite = lazyWithRetry(() => import('pages/Favourite'));
const NotFound = lazyWithRetry(() => import('pages/NotFound'));
const PrivacyPolicy = lazyWithRetry(() => import('pages/PrivacyPolicy'));
const TermsOfUse = lazyWithRetry(() => import('pages/TermsOfUse'));
const AboutCompany = lazyWithRetry(() => import('pages/AboutCompany'));
const PlaystorePopup = lazyWithRetry(() => import('components/PlaystorePopup'));
const ContactUs = lazyWithRetry(() => import('pages/ContactUs'));
const Pricing = lazyWithRetry(() => import('pages/Pricing'));
const Original = lazyWithRetry(() => import('pages/Originals'));

// a function to retry loading a chunk to avoid chunk load error for out of date code
// const lazyRetry = function(componentImport) {
//   return new Promise((resolve, reject) => {
//       // check if the window has already been refreshed
//       const hasRefreshed = JSON.parse(
//           window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
//       );
//       // try to import the component
//       componentImport().then((component) => {
//           window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
//           resolve(component);
//       }).catch((error) => {
//           if (!hasRefreshed) { // not been refreshed yet
//               window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
//               return window.location.reload(); // refresh the page
//           }
//           reject(error); // Default error behaviour as already tried refresh
//       });
//   });
// };

const IndicatorContext = React.createContext();

function IndicatorProvider({ children }) {
  const [active, setActive] = useState(false);
  const value = useMemo(() => ({ active, setActive }), [active, setActive]);
  return <IndicatorContext.Provider value={value}>{children}</IndicatorContext.Provider>;
}


function Indicator() {
  const { active } = useContext(IndicatorContext);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setPercent((percent) => (percent < 100 ? percent + 10 : 100));
    }, 200);
  });
  return active ? <Progress percent={percent} /> : null;
}

function IndicatorFallback() {
  const { setActive } = useContext(IndicatorContext);
  useEffect(() => {
    setActive(true);
    return () => setActive(false);
  });
  return null;
}

function PrivateRoute({ children, ...rest }) {
  const { currentUser, status } = useSelector((state) => state.user);
  if (!currentUser && status === 'pending') return <Loader />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
toast.configure();

// const addStyle = (url) => {
//   const style = document.createElement('link');
//   style.href = url;
//   style.rel = 'stylesheet';
//   style.async = true;

//   document.head.appendChild(style);
// };

export default function App() {
  const { theme } = useSelector((state) => state.theme);
  const { currentUser } = useSelector((state) => state.user);
  const lokation = useLocation();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = firebase.analytics();
  const title = window.platform_info.title;
  const iosUser = localStorage.getItem(`ios_sub_user_${title}`) && localStorage.getItem(`ios_sub_user_${title}`);
  const gpUser = JSON.parse(localStorage.getItem(`gp_user_${title}`));
  const robiUser = JSON.parse(localStorage.getItem(`robi_user_${title}`));
  useAnanymousCheck();

  React.useEffect(() => {
    // Setting Vast Tag
    const vastAdRef = firebase.database().ref('vast_ads');
    vastAdRef.child('web').once('value', (snap) => {
      if (snap.exists()) {
        dispatch(setVastTag(snap.val()))
      }
    })
  }, [])


  useEffect(() => {
    const hash = searchParams.get('hash');
    if (hash) {
      try {
        const user = jwt(hash);
        if (user['subscribe'] !== undefined) {
          localStorage.setItem(`astha_user_${title}`, JSON.stringify(user));
          localStorage.setItem(`access_token_r_${title}`, JSON.stringify(hash));
          localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(hash));
          localStorage.removeItem(`access_token_temp_${title}`);
          localStorage.removeItem(`refresh_token_temp_${title}`);
          history.push('/');
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [searchParams.get('hash')]);

  // Gp Users access to homepage
  useEffect(() => {
    const token = searchParams.get('token');
    let decodedGPTOken;
    if (token) {
      decodedGPTOken = jwt(token);
    }
    // if url coming with series or video route
    // const fromSeriesOrVideo = lokation.pathname.startsWith('/video') || lokation.pathname.startsWith('/series');

    if (decodedGPTOken?.partner && decodedGPTOken?.partner == "GP_MYGP") {
      try {
        console.log("Triggering from Appjs component");
        callbackTypeOne(decodedGPTOken?.exp);
        
        localStorage.setItem(`gp_user_${title}`, JSON.stringify(decodedGPTOken));
        localStorage.setItem(`access_token_r_${title}`, JSON.stringify(token));
        localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(token));
        localStorage.removeItem(`access_token_temp_${title}`);
        localStorage.removeItem(`refresh_token_temp_${title}`);
        // if (!fromSeriesOrVideo) {
        //   history.push(`/`);
        // }
      } catch (error) {
        console.log('error', error);
      }
    }
    if (decodedGPTOken?.partner && decodedGPTOken?.partner == "ROBI_MYROBI") {
      try {
        localStorage.setItem(`robi_user_${title}`, JSON.stringify(decodedGPTOken));
        localStorage.setItem(`access_token_r_${title}`, JSON.stringify(token));
        localStorage.setItem(`refresh_token_r_${title}`, JSON.stringify(token));
        localStorage.removeItem(`access_token_temp_${title}`);
        localStorage.removeItem(`refresh_token_temp_${title}`);
        // if (!fromSeriesOrVideo) {
        //   history.push(`/`);
        // }
      } catch (error) {
        console.log('error', error);
      }
    }
  }, [searchParams.get('token')]);

  let gTag = window.platform_info.google_analytics_key;
  const adsToken = window?.google_adsense?.adsense_code;
  const apikey = window?.platform_info?.api_key;
  const tawktoSource = window?.tawkto?.src;

  useGaTracker();
  // addStyle('http://localhost:9000/rockerzs.css');
  const injectAds = () => {
    const script = document.createElement('script');
    script.src = adsToken;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';

    document.body.appendChild(script);
  };
  useEffect(() => {
    if (adsToken && apikey === 'iscreen') {
      // injectAds();
    }
  }, [adsToken, apikey]);

  // Tawkto Integration
  useEffect(() => {
    if (tawktoSource) {
      var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = tawktoSource;
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }, [tawktoSource])

  useEffect(() => {
    if (currentUser) {
      console.log("Checking whether new token dispatched for subscription");
      const accessToken = localStorage.getItem(`access_token_r_${title}`);
      const user = jwt(accessToken);
      dispatch(setUserSub(user));
    }
  }, [currentUser]);

  //Astha User
  const asthaUser = JSON.parse(localStorage.getItem(`astha_user_${title}`));

  useEffect(() => {
    if ((window.location.href == "https://iscreen.com.bd/" && asthaUser)) {
      console.log("Astha Hit count");
      analytics.logEvent('third_party_inapp_hit', {
        platform: window?.platform_info.api_key,
        partner: asthaUser?.username,
        hit: 1
      });
    }
  }, [asthaUser])

  return (
    <IndicatorProvider>
      <Indicator />
      <ScrollToTop />
      <MetaPixel />
      <ErrorBoundary>
        <Helmet>
          <title>{title} {apikey === 'iscreen' ? '- Movies, Series & Drama' : ''}</title>
          <meta
            name="description"
            content={`${title} is a streaming Web App Where you can play your favourite songs and videos`}
          />
          <link rel="icon" type="image/png" href={window.platform_info.favIcon} sizes="16x16" />
          <meta property="og:title" content={title} />

          <meta property="og:image" content={`${window.platform_info.logo}`} />
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${gTag}`}></script>
        </Helmet>

        {(asthaUser && asthaUser.username == 'astha' || gpUser || robiUser) ? '' : iosUser ? '' : <PlaystorePopup />}
        <Layout>
          <Suspense fallback={<IndicatorFallback />}>
            <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
              <GlobalStyles />
              <ScrollToTopButton />
              {iosUser || gpUser || robiUser ? '' : <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="accept-cookie"
                style={{ background: '#2B373B' }}
                buttonStyle={{
                  color: '#fff',
                  fontSize: '13px',
                  backgroundColor: 'var(--hover-color)',
                  fontWeight: 'bold'
                }}
                expires={150}>
                This website uses cookies to ensure you get the best experience on our website.{' '}
                <a
                  target="_blank"
                  href={`https://iscreen.com.bd/privacy-policy`}
                  style={{ color: '#fff' }}>
                  Learn more
                </a>
              </CookieConsent>}
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/coupon-login">
                  <CouponAuth />
                </Route>
                <Route exact path="/payment/confirm/:status">
                  <PaymentConfirmation />
                </Route>
                <Route exact path="/callback">
                  <CallbackPaymentPage />
                </Route>

                <Route exact path="/videos">
                  <Videos />
                </Route>
                <Route exact path="/movie">
                  <Movie />
                </Route>
                <Route exact path="/series">
                  <Movie />
                </Route>
                <Route exact path="/songs">
                  <Audios />
                </Route>
                <Route exact path="/feed">
                  <Original />
                </Route>
                {/*
                <Route path="/artist/:id">
                  <ArtistPage />
                </Route> */}
                <Route path="/artist/:slug">
                  <ArtistPageRebuilt />
                </Route>
                <Route exact path="/video/:id">
                  <VideoDetail />
                </Route>
                <Route exact path="/content/video/:slug">
                  <VideoDetailSlug />
                </Route>
                <Route exact path="/content/tv/:slug">
                  <VideoDetailSlug />
                </Route>
                <Route exact path="/channel/:id">
                  <ChannelPlay />
                </Route>
                <Route exact path="/channels">
                  <ChannelsSeemore />
                </Route>
                <Route exact path="/series/:id">
                  <SeriesDetail />
                </Route>
                <Route exact path="/content/series/:slug">
                  <SeriesDetailSlug />
                </Route>
                <Route exact path="/catchupTV/:id">
                  <CatchupDetail />
                </Route>
                <Route exact path="/audio/:id">
                  <AudioDetail />
                </Route>
                <Route exact path="/content/audio/:slug">
                  <AudioDetailSlug />
                </Route>
                <Route exact path="/album/:id">
                  <AlbumDetail />
                </Route>
                <Route exact path="/content/album/:slug">
                  <AlbumDetailSlug />
                </Route>
                <Route exact path="/category/:id">
                  <CategoryPage />
                </Route>
                <Route exact path="/content/category/:slug">
                  <CategoryPageSlug />
                </Route>
                <Route exact path="/recommendation/:type">
                  <RecommendationPage />
                </Route>
                <Route exact path="/favourite">
                  <Favourite />
                </Route>
                <Route exact path="/favourites/:type">
                  <FavouriteSeeAll />
                </Route>
                <Route exact path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route exact path="/terms-condition">
                  <TermsOfUse />
                </Route>
                <Route exact path="/about-company">
                  <AboutCompany />
                </Route>
                <Route exact path="/contact-us">
                  <ContactUs />
                </Route>
                <Route exact path="/refund-policy">
                  <Pricing />
                </Route>
                <Route exact path="/allartists">
                  <AllPopularArtist />
                </Route>

                <PrivateRoute exact path="/settings/:name">
                  <Settings />
                </PrivateRoute>
                <PrivateRoute exact path="/search/:type">
                  <SearchPage />
                </PrivateRoute>

                <Route exact path="/subs">
                  <SubscribeRedirect />
                </Route>

                <PrivateRoute exact path="/subscribe">
                  <PremiumModal2 />
                </PrivateRoute>
                <PrivateRoute exact path="/subscribe/:id">
                  <Package1 />
                </PrivateRoute>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Modals />
            </ThemeProvider>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </IndicatorProvider>
  );
}
